import { IconsEnum } from '../../../../../assets/icons/types';
import { CompanyNanoID } from '../../../../companies/companiesTypes';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { CreateItemTypeFormResponseType } from '../../forms/CreateItemTypeForm/CreateItemTypeForm.types';

import { useCreateItemTypeForm } from '../../forms/CreateItemTypeForm/hooks/useCreateItemTypeForm';

import { CreateItemTypeForm } from '../../forms/CreateItemTypeForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { itemTypesKeys, words } from '../../../../../locales/keys';
import { CreateItemTypeCacheKeys } from '../../../itemTypesTypes';

const CREATE_ITEM_CATEGORY_FORM = 'create-item-category-form';

interface CreateItemTypeModalButtonProps {
  afterCreate?: (itemType: CreateItemTypeFormResponseType) => void;
  cacheKeys?: CreateItemTypeCacheKeys;
  className?: ClassName;
  companyNanoId?: CompanyNanoID;
  disabled?: IsDisabled;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
}

function CreateItemTypeModalButton({
  afterCreate,
  cacheKeys = [],
  className,
  companyNanoId,
  disabled,
  i18nText,
  i18nTextClassName,
  icon,
  iconClassName
}: CreateItemTypeModalButtonProps) {
  const {
    control,
    registerFields,
    handleCreateItemType,
    resetCreateItemTypeForm,
    createItemTypeReset,
    createItemTypeLoading,
    createItemTypeErrorMessage,
    validationErrors,
    watchPrice,
    watchCurrency,
    currencyExchangeRate,
    itemTagsFilters,
    isNewItemName
  } = useCreateItemTypeForm({
    cacheKeys,
    companyNanoId,
    afterCreate
  });

  return (
    <FormModalButton
      className={
        className ||
        'py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      disabled={disabled}
      form={CREATE_ITEM_CATEGORY_FORM}
      i18nSubmitText={words.create}
      i18nText={i18nText || itemTypesKeys.create}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={itemTypesKeys.create}
      icon={icon}
      iconClassName={iconClassName}
      isLoading={createItemTypeLoading}
      onClose={createItemTypeReset}
      onOpen={resetCreateItemTypeForm}
      onSubmit={handleCreateItemType}
    >
      <CreateItemTypeForm
        control={control}
        currencyExchangeRate={currencyExchangeRate}
        form={CREATE_ITEM_CATEGORY_FORM}
        isLoading={createItemTypeLoading}
        nameValidationError={validationErrors.nameValidationError}
        priceValidationError={validationErrors.priceValidationError}
        itemCategoryValidationError={
          validationErrors.itemCategoryValidationError
        }
        registerName={registerFields.registerName}
        registerDescription={registerFields.registerDescription}
        registerPrice={registerFields.registerPrice}
        watchCurrency={watchCurrency}
        itemTagsFilters={itemTagsFilters}
        watchPrice={watchPrice}
        isNewItemName={isNewItemName}
      />
      <div className="px-4">
        <AlertMessage message={createItemTypeErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default CreateItemTypeModalButton;

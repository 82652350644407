import { Currencies, MoneyType } from '../../../../types';

interface PrepareItemForConvertOptions {
  price: MoneyType;
  viewPrice?: MoneyType;
  viewPriceCurrency?: Currencies;
}

export default function prepareItemForConvert({
  price,
  viewPrice,
  viewPriceCurrency
}: PrepareItemForConvertOptions) {
  return {
    price: viewPrice || (viewPriceCurrency === Currencies.USD ? price : 0) || 0,
    currency: viewPriceCurrency || Currencies.USD
  };
}

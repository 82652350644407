import React from 'react';
import { Control } from 'react-hook-form';

import {
  Currencies,
  ErrorMessage,
  FormID,
  MoneyType,
  RegisterFormFieldType,
  currenciesSymbols
} from '../../../../../types';
import {
  CreateItemTypeIsLoading,
  ItemTypeFields
} from '../../../itemTypesTypes';
import { CreateItemTypeFormData } from './CreateItemTypeForm.types';
import { FetchItemTagsFilters } from '../../../../itemTags/itemTagsTypes';

import { CheckBoxDateAtField } from '../../../../../helpers/FormFields/CheckBoxDateAtField';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { CurrencySelectField } from '../../../../../helpers/FormFields/CurrencySelectField';
import { DropzoneFileUrlField } from '../../../../../helpers/FormFields/DropzoneFileUrlField';
import { Form } from '../../../../../helpers/Form';
import { ExchangeRatesList } from '../../../../currencyRates/helpers/ExchangeRatesList';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { ItemCategoriesSelectField } from '../../../../../helpers/FormFields/ItemCategoriesSelectField';
import { PriceInCurrencyAlertMessage } from '../../../../currencyRates/helpers/PriceInCurrencyAlertMessage';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';
import { Translate } from '../../../../../helpers/Translate';
import { ItemTagsSelectField } from '../../../../itemTags/helpers/ItemTagsSelectField';

import { amountDivide } from '../../../../../utils/amountDivide';

import { baseCurrenciesList } from '../../../../currencyRates/currencyRatesConstants';
import { ItemTypesPermissions } from '../../../itemTypesConstants';
import {
  formsFields,
  itemCategoriesKeys,
  words
} from '../../../../../locales/keys';

interface CreateItemTypeFormProps {
  control: Control<CreateItemTypeFormData>;
  form: FormID;
  isLoading?: CreateItemTypeIsLoading;
  nameValidationError?: ErrorMessage;
  itemCategoryValidationError?: ErrorMessage;
  priceValidationError?: ErrorMessage;
  registerName: RegisterFormFieldType<HTMLInputElement>;
  registerDescription: RegisterFormFieldType<HTMLTextAreaElement>;
  registerPrice: RegisterFormFieldType<HTMLInputElement>;
  watchPrice: MoneyType;
  watchCurrency: Currencies;
  currencyExchangeRate: number;
  itemTagsFilters?: FetchItemTagsFilters;
  isNewItemName?: boolean;
}

function CreateItemTypeForm({
  control,
  currencyExchangeRate,
  form,
  isLoading,
  nameValidationError,
  itemCategoryValidationError,
  itemTagsFilters,
  priceValidationError,
  registerName,
  registerDescription,
  registerPrice,
  watchPrice,
  watchCurrency,
  isNewItemName
}: CreateItemTypeFormProps) {
  console.log(itemCategoryValidationError);
  return (
    <Form className="flex-1 overflow-y-auto px-2 text-sm" id={form}>
      <div className="p-4 space-y-4">
        <div>
          <ItemCategoriesSelectField
            control={control}
            error={itemCategoryValidationError}
            disabled={isLoading}
            name={ItemTypeFields.ITEM_CATEGORY_ID}
            i18nLabel={formsFields.category}
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            i18nPlaceholder={itemCategoriesKeys.selectCategory}
          />
        </div>

        <div>
          {isNewItemName ? (
            <ItemTagsSelectField<CreateItemTypeFormData>
              control={control}
              name={ItemTypeFields.ITEM_TAG_IDS}
              disabled={isLoading}
              inputWrapperClassName="mt-1 relative"
              i18nLabel={formsFields.name}
              initialFilters={itemTagsFilters}
              multi
              sortable
            />
          ) : (
            <InputField
              disabled={isLoading}
              error={nameValidationError}
              inputWrapperClassName="mt-1 relative"
              i18nLabel={formsFields.name}
              name={registerName.name}
              ref={registerName.ref}
              onChange={registerName.onChange}
              type="text"
            />
          )}
        </div>

        <CheckPermissions
          action={
            ItemTypesPermissions.CREATE_ITEM_TYPES_TABLE_DESCRIPTION_FIELD
          }
        >
          <div>
            <TextAreaField
              name={registerDescription.name}
              ref={registerDescription.ref}
              onChange={registerDescription.onChange}
              i18nLabel={formsFields.description}
              labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
              disabled={isLoading}
              rows={5}
            />
          </div>
        </CheckPermissions>

        <CheckPermissions
          action={ItemTypesPermissions.CREATE_ITEM_TYPES_TABLE_IMAGE_URL_FIELD}
        >
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              <Translate id={formsFields.imageUrl} />
            </label>

            <div className="mt-1 relative">
              <DropzoneFileUrlField
                control={control}
                name={ItemTypeFields.IMAGE_URL}
                type="images"
                disabled={isLoading}
              />
            </div>
          </div>
        </CheckPermissions>

        <CheckPermissions
          action={
            ItemTypesPermissions.CREATE_ITEM_TYPES_TABLE_NEW_CLIENT_DEFAULT_AT_FIELD
          }
        >
          <div>
            <CheckBoxDateAtField<CreateItemTypeFormData>
              control={control}
              disabled={isLoading}
              i18nLabel={formsFields.defaultForNewClients}
              name={
                ItemTypeFields.NEW_CLIENT_DEFAULT_AT as 'newClientDefaultAt'
              }
              id="new-client-default-at"
            />
          </div>
        </CheckPermissions>

        <div>
          <label
            htmlFor={registerPrice.name}
            className="block text-sm font-medium text-gray-700 dark:text-gray-300"
          >
            <Translate id={words.price} />
          </label>
          <div className="mt-1 relative">
            <InputField
              disabled={isLoading}
              error={priceValidationError}
              i18nPlaceholder={words.price}
              addInputClassName="pl-7 pr-24 number-appearance-none"
              prefix={currenciesSymbols[watchCurrency] || watchCurrency || '$'}
              name={registerPrice.name}
              ref={registerPrice.ref}
              onChange={registerPrice.onChange}
              type="number"
              withoutScroll
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <CurrencySelectField<CreateItemTypeFormData>
                inputWrapperClassName="h-full border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                classNamePrefix="av"
                control={control}
                name={ItemTypeFields.VIEW_PRICE_CURRENCY}
              />
            </div>
          </div>
        </div>
        <div className="space-y-4">
          {watchCurrency !== Currencies.USD && (
            <PriceInCurrencyAlertMessage
              currency={Currencies.USD}
              value={amountDivide(watchPrice, currencyExchangeRate)}
            />
          )}

          <ExchangeRatesList currencies={baseCurrenciesList} />
        </div>
      </div>
    </Form>
  );
}

export default CreateItemTypeForm;

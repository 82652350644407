import React, { useCallback, useEffect, useMemo } from 'react';
import { Control, FieldPath } from 'react-hook-form';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import {
  ClassName,
  ErrorMessage,
  I18nText,
  IsDisabled,
  MoneyType
} from '../../../../types';
import {
  FetchItemTypesCacheKey,
  FetchFinItemTypesFilters
} from '../../itemTypesTypes';
import { ItemTypeIdsChangeCallbackType } from './ItemTypeIdsSelectField.types';
import { Currencies } from '../../../../types';
import { ItemTypeID } from '../../itemTypesTypes';
import { ItemItemTypeID } from '../../../items/itemsTypes';
import {
  FormatGroupLabelType,
  FormatOptionLabelType,
  MultiSelectDataType
} from '../../../../helpers/MultiSelect/types';
import {
  ItemTypeIdsSelectDataType,
  ItemTypeIdsSelectGroupedDataType
} from './ItemTypeIdsSelectField.types';
import { useFinPaginatedItemTypes } from '../../hooks/useFinPaginatedItemTypes';

import {
  FETCH_SELECT_ITEM_TYPES_QUERY,
  FetchSelectItemTypesQueryResponse
} from '../../queries/fetchSelectItemTypes.query';

import { useConvertCurrencyRates } from '../../../currencyRates/hooks/useConvertCurrencyRates';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';
import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';
import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { GroupedMultiSelectField } from '../../../../helpers/FormFields/GroupedMultiSelectField';
import { MoneyHelper } from '../../../../helpers/MoneyHelper';
import { prepareItemForConvert } from '../../utils/prepareItemForConvert';

import { INITIAL_ITEM_TYPES_FILTERS } from '../../itemTypesConstants';
import { ItemTagsPermissions } from '../../../itemTags/itemTagsConstants';
import { filterConfigOnlyLabels } from '../../../../helpers/MultiSelect';

export interface ItemTypesSelectFieldProps<T> {
  defaultItemTypeId?: ItemItemTypeID;
  autoFocus?: boolean;
  cacheKey: FetchItemTypesCacheKey;
  classNamePrefix?: string;
  control: Control<T>;
  disabled?: IsDisabled;
  disabledItemTypesLoading?: IsDisabled;
  errorMessage?: ErrorMessage;
  i18nLabel?: I18nText;
  i18nPlaceholder?: I18nText;
  inputWrapperClassName?: ClassName;
  isLoading?: boolean;
  itemTypesFilters?: FetchFinItemTypesFilters;
  name: FieldPath<T>;
  onBlur?: () => void;
  onChange?: ItemTypeIdsChangeCallbackType;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  openMenuOnFocus?: boolean;
  currencyExchangeRate?: MoneyType;
  currencyPrefix?: string;
  watchCurrency?: Currencies;
  menuPosition?: 'absolute' | 'fixed';
}

export function ItemTypeIdsSelectField<T>({
  defaultItemTypeId,
  autoFocus,
  classNamePrefix,
  cacheKey,
  control,
  disabled,
  disabledItemTypesLoading,
  errorMessage,
  i18nLabel,
  i18nPlaceholder,
  inputWrapperClassName,
  isLoading,
  itemTypesFilters = INITIAL_ITEM_TYPES_FILTERS,
  name,
  onBlur,
  onChange,
  onMenuClose,
  onMenuOpen,
  openMenuOnFocus,
  currencyExchangeRate = 1,
  currencyPrefix = Currencies.USD,
  watchCurrency = Currencies.USD,
  menuPosition = 'fixed'
}: ItemTypesSelectFieldProps<T>) {
  const { itemTypes, itemTypesLoading, filterItemTypes, itemTypesError } =
    useFinPaginatedItemTypes<FetchSelectItemTypesQueryResponse>({
      cacheKey,
      query: FETCH_SELECT_ITEM_TYPES_QUERY,
      initialLimit: 1000,
      initialFilters: itemTypesFilters,
      options: {
        enabled: !disabledItemTypesLoading,
        enabledPlaceholder: !disabledItemTypesLoading,
        withoutPrefetch: true
      }
    });

  const currentUser = useCurrentUser();

  const isNewItemName = currentUser.hasPermissions(
    ItemTagsPermissions.READ_ITEM_TAGS_FOR_ITEM_NAME_FEATURE
  );

  useShowToastOnErrorChange({ error: itemTypesError });

  const prevFilters = usePreviousValue(itemTypesFilters);

  useEffect(() => {
    if (prevFilters !== itemTypesFilters) {
      filterItemTypes(itemTypesFilters);
    }
  }, [prevFilters, filterItemTypes, itemTypesFilters]);

  const { convertCurrencyCrossExchangeUsd } = useConvertCurrencyRates();

  const groupedOptions = useMemo<ItemTypeIdsSelectGroupedDataType[]>(() => {
    const groupedItemTypes = groupBy(
      itemTypes,
      (itemType) => itemType.itemCategory?.id
    );

    return values(groupedItemTypes).map((itemTypesGroup) => ({
      label: itemTypesGroup[0]?.itemCategory?.name,
      options: itemTypesGroup.map((itemType) => {
        const preparedItem = prepareItemForConvert(itemType);

        const price =
          convertCurrencyCrossExchangeUsd(
            preparedItem.price,
            preparedItem.currency,
            watchCurrency
          ) || itemType.price;

        const newItemName =
          map(
            sortBy(itemType.itemTypeItemTags, 'position'),
            'itemTag.name'
          )?.join(' ') || itemType.name;

        return {
          label: isNewItemName || !itemType.name ? newItemName : itemType.name,
          value: itemType.id,
          price,
          viewPrice: itemType.viewPrice || price,
          viewPriceCurrency: itemType.viewPriceCurrency || watchCurrency,
          description: itemType.description
        };
      })
    }));
  }, [
    convertCurrencyCrossExchangeUsd,
    isNewItemName,
    itemTypes,
    watchCurrency
  ]);

  const handleOnChange = useCallback<(value: ItemTypeID) => void>(
    (newValue) =>
      onChange?.(find(itemTypes, (itemType) => itemType.id === newValue)),
    [itemTypes, onChange]
  );

  const formatGroupLabel = useCallback<FormatGroupLabelType>(
    (group) => (
      <div className="pt-2 pb-1">
        <div className="dark:text-gray-600 text-gray-400 text-xs normal-case font-normal leading-5">
          {group.label}
        </div>
        <hr className="flex-1 dark:border-gray-700" />
      </div>
    ),
    []
  );

  const formatOptionLabel = useCallback<FormatOptionLabelType>(
    (option: ItemTypeIdsSelectDataType, { context }) => {
      if (context === 'menu') {
        return (
          <div className="text-gray-800 dark:text-gray-200 cursor-default focus:text-gray-900 dark:focus:text-gray-100 focus:bg-gray-100 dark:focus:bg-gray-900 select-none relative focus:ring-base focus:ring-offset-0 flex justify-between gap-2 items-center">
            <span className="block truncate">{option.label}</span>
            <span className="italic text-2xs text-gray-500">
              <MoneyHelper value={option.price} currency={currencyPrefix} />
            </span>
          </div>
        );
      }

      return option.label;
    },
    [currencyPrefix]
  );

  const defaultValue = useMemo<MultiSelectDataType>(() => {
    const defaultItemType = find(
      itemTypes,
      (itemType) => itemType.id === defaultItemTypeId
    );

    return isEmpty(defaultItemType)
      ? null
      : { label: defaultItemType.name, value: defaultItemType.id };
  }, [defaultItemTypeId, itemTypes]);

  return (
    <GroupedMultiSelectField<T>
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      classNamePrefix={
        classNamePrefix || (errorMessage ? 'av_select_error' : 'av_select')
      }
      control={control}
      data={groupedOptions}
      disabled={disabled}
      emptyValue={undefined}
      error={errorMessage}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isLoading={isLoading || itemTypesLoading}
      isSearchable
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      menuPosition={menuPosition}
      multi={false}
      name={name}
      onBlur={onBlur}
      onChange={handleOnChange}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      openMenuOnFocus={openMenuOnFocus}
      optionsLoading={itemTypesLoading}
      filterConfig={filterConfigOnlyLabels}
    />
  );
}

export default ItemTypeIdsSelectField;
